'use client'

import { useState, useEffect } from 'react'

const banners = [
  { 
    title: "Potencialize seu Negócio com Inovação Digital", 
    summary: 'Descubra como a LUMUENU VETU pode revolucionar sua empresa através de soluções tecnológicas inovadoras. Nossa abordagem focada em eficiência e excelência técnica está pronta para elevar o seu negócio a novos patamares. Explore o futuro da sua empresa conosco!' 
  },
  { 
    title: "Transformamos sua Visão em Realidade", 
    summary: 'Na LUMUENU VETU, estamos comprometidos em tornar sua visão empresarial uma realidade palpável. Nossa abordagem focada em inovação e excelência tecnológica capacita sua empresa a alcançar novos patamares de sucesso. Descubra como podemos ajudá-lo a moldar o futuro do seu negócio.' 
  },
]

export default function Hero() {
  const [ current, setCurrent ] = useState(banners[0]);
  const [ count, setCount ] = useState(0);

  const nextPresentation = ()=>{
    if(banners.length > count){
      setCurrent(banners[count]);
      setCount(count+1);
    }else{
      setCount(0);
    }
  }

  useEffect(()=>{
    setTimeout(()=>{
      nextPresentation();
    }, 5000);
  }, [count]);

  return(
    <div className="bg-white h-[100vh]">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="mx-auto w-full max-w-2xl py-32 sm:py-48 lg:py-56">

          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              {current.title}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {current.summary}
            </p>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </div>
  )
}
