import { useContext } from 'react';
import AppContainer from './config/context';
import SideNav from "./components/sidenav";
import Hero from "./components/hero";
import CtaSection from "./components/cta-section";
import Header from "./components/header";
import Footer from "./components/footer";

import Contacts from './pages/contacts';

const osSolutions = [
  "Gerenciamento e Manutenção",
  "Suporte Técnico e Solução de Problemas",
  "Segurança e Proteção",
  "Otimização e Performance",
  "Backup e Recuperação",
  "Instalação e Configuração"
]
const webSolutions = [
  "Lojas Virtuais (e-Commerce)",
  "Portais de Notícias e Informação",
  "Plataformas de Serviços Financeiros",
  "Sistemas de Gestão Empresarial (ERP)",
  "Soluções de E-Learning e Educação Online"
];

const networSolutions = [
  "Planejamento e Implementação de Infraestrutura",
  "Segurança e Gerenciamento",
  "Escalabilidade e Integração"
];

export default function Home(){
  const { currentRoute } = useContext(AppContainer);

  return(
    <main>
      <SideNav />
     { currentRoute === "/" && 
      <>
        <Hero />
        <div>
          <Header>Nossos Serviços</Header>

          <CtaSection
            id="services"
            title="Sistemas Operativos"
            description="Nosso serviço de sistema operacional personalizado oferece soluções adaptadas às necessidades específicas da sua empresa ou organização. Combinando expertise técnica avançada e uma abordagem centrada no cliente, desenvolvemos sistemas operacionais que otimizam a eficiência, segurança e compatibilidade dos seus sistemas informáticos."
            image="systems.webp" 
          >
            <ul className="mt-5 lg:ml-8 space-y-3">
              {osSolutions.map((props, index)=> <li key={index} className="flex items-center w-auto gap-2 font-medium"> 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                </svg>
                <span className="rounded-xl px-3 py-1 hover:text-blue-700 hover:bg-blue-200">{props}</span>
              </li>)}
            </ul>
          </CtaSection>

          <CtaSection
            direction="right"
            title="Desenvolvimento Web"
            description="Oferecemos soluções de desenvolvimento web que combinam design front-end atraente com um back-end robusto e seguro, garantindo um desempenho confiável e uma navegação fluida. Nossos serviços incluem a integração eficiente de sistemas e plataformas, otimização de performance para carregamento rápido e suporte contínuo para manutenção e atualizações. Eleve seu negócio com um site que realmente faz a diferença."
            image="dev.webp" 
          >
            <ul className="mt-5 lg:ml-8 space-y-3">
              {webSolutions.map((props, index)=> <li key={index} className="flex items-center w-auto gap-2 font-medium"> 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                </svg>
                <span className="rounded-xl px-3 py-1 hover:text-blue-700 hover:bg-blue-200">{props}</span>
              </li>)}
            </ul>
          </CtaSection>

          <CtaSection
            title="Redes de Computadores"
            description="Nossa abordagem inclui o planejamento e a implementação de infraestrutura de rede, configuração de equipamentos como roteadores e switches, e a instalação de sistemas de segurança para proteger dados e informações. Também proporcionamos suporte contínuo para assegurar que sua rede opere com máxima eficiência e estabilidade, atendendo às necessidades específicas do seu negócio e contribuindo para o crescimento e sucesso da sua empresa."
            image="network.webp" 
          >
            <ul className="mt-5 lg:ml-8 space-y-3">
                {networSolutions.map((props, index)=> <li key={index} className="flex items-center w-auto gap-2 font-medium"> 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                  </svg>
                  <span className="rounded-xl px-3 py-1 hover:text-blue-700 hover:bg-blue-200">{props}</span>
                </li>)}
              </ul>
          </CtaSection>
        </div>
      </>
      }

      { currentRoute === "/contacts" && <Contacts /> }

      <Footer />
    </main>
  )
}
