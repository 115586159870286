import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import GoogleIcon from '@mui/icons-material/Google';
import YouTubeIcon from '@mui/icons-material/YouTube';
import RssFeedIcon from '@mui/icons-material/RssFeed';

export default function Footer(){
  return(
    <footer className='bg-slate-900 text-white pt-8 md:pt-32 px-10'>
      <div className='md:flex'>
        <div className='md:w-[50%] space-y-6'>
          <img
            alt="Your Company"
            src="lv_white.png"
            className="h-8 w-auto"
          />

          <p className='text-gray-300'>A LUMUENU VETU TECNOLOGIA é uma empresa que atua nas áreas de sistemas operativos, redes de computadores, desenvolvimento web.
            Fornecendo soluções baseadas em Tecnologias.
          </p>
          <div className='flex gap-5 text-gray-400'>
            <a href="#" className='hover:text-white'>
              <FacebookIcon />
            </a>
            <a href="#" className='hover:text-white'>
              <LinkedInIcon />
            </a>
            <a href="#" className='hover:text-white'>
              <AlternateEmailIcon />
            </a>
            <a href="#" className='hover:text-white'>
              <WhatsAppIcon />
            </a>
            <a href="#" className='hover:text-white'>
              <YouTubeIcon />
            </a>
            <a href="#" className='hover:text-white'>
              <InstagramIcon />
            </a>
          </div>
        </div>

        <div className='space-y-8 md:space-y-0 mt-8 md:mt-0 w-full md:flex-grow md:flex md:justify-around md:gap-32 md:mx-10'>
          <div className='flex flex-col gap-3'>
            <h2 className='text-md font-medium'>Soluções</h2>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Consultoria</a>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Atendimento ao Publico</a>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Analise de Compras</a>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Avaliação de Infraestrutura</a>
          </div>
          <div className='flex flex-col gap-3'>
            <h2 className='text-md font-medium'>Suporte</h2>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Preços</a>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Guia</a>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Prazo de Garantias</a>
          </div>
          <div className='flex flex-col gap-3'>
            <h2 className='text-md font-medium'>Empresa</h2>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Sobre</a>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Blog</a>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Oportunidades</a>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Investidores</a>
          </div>

          <div className='flex flex-col gap-3'>
            <h2 className='text-md font-medium'>Legal</h2>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Privacidade</a>
            <a href="#" className='text-gray-300 hover:text-white transition duration-300'>Termos de uso dos cookies</a>
          </div>
        </div>
      </div>

      <div className='mt-16 py-6 border-t-[1px] border-white/25'>
        <p className='md:text-sm text-xs text-center md:text-start text-gray-300'>&copy; 2024 Lumuenu Vetu Tecnologia, Lda. Todos os direitos reservados.</p>
      </div>
    </footer>
  )
}