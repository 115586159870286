import clsx from "clsx";
export default function CtaSection({
  title,
  description,
  children,
  direction,
  image,
  id
}:{
  title: string;
  description: string;
  children?:React.ReactNode;
  direction?: 'right' | 'left',
  image: string;
  id?: string;
}){
  return(
    <div id={id} className={clsx("shadow lg:flex gap-16 items-center bg-gray-50 px-8 md:px-16 py-10", 
      {
        "flex-row-reverse bg-blue-50": direction === 'right'
      }
    )}>
      <img alt="img" className="rounded-xl w-auto md:h-96 mb-3 md:mb-0" src={image} />

      <div className={clsx({
        "pr-8 md:pr-32": !direction || direction === 'left',
        "lg:pl-8 lg:pl-32": direction === 'right',
      })}>
        <h2 className="text-blue-600 text-xl md:text-4xl font-bold">{title}</h2>
        <p className=" text-lg mt-3 md:text-justify">{description}</p>
        {children}
      </div>
    </div>
  )
}