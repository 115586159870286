import { useState } from 'react';
import { 
  Disclosure, 
  DisclosureButton, 
  DisclosurePanel 
} from '@headlessui/react';
import { 
  Bars3Icon, 
  XMarkIcon 
} from '@heroicons/react/24/outline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton } from '@radix-ui/themes';
import AppContainer from '../config/context';

const navigation = [
  { name: 'Início', href: '/', current: true,  },
  { name: 'Serviços', href: '/services', current: false },
  { name: 'Contactos', href: '/contacts', current: false },
  { name: 'Sobre', href: '/about', current: false },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function SideNav(){
  const [ currentRoute, setCurrentRoute ] = useState(window.location.pathname);

  return (
    <AppContainer.Provider value={{
      currentRoute
    }}>
    <Disclosure as="nav" className="fixed z-10 w-full bg-white/25 backdrop-blur border-b-[1px]">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-blue-500 hover:bg-blue-300 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
            </DisclosureButton>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <img
                alt="Lumuenu Vetu"
                src="lv.png"
                className="h-8 w-auto"
              />
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {navigation.map((item) => (
                  <button onClick={()=>setCurrentRoute(item.href)}
                    key={item.name}
                    className={classNames(
                      item.href === currentRoute ? 'bg-blue-500/20 text-blue-500' : 'text-blue-500 hover:bg-blue-500/20',
                      'rounded-md px-3 py-2 text-sm font-medium',
                    )}
                  >
                    {item.name}
                    </button>
                ))}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <IconButton className='block md:hidden bg-green-500'>
              <WhatsAppIcon />
            </IconButton>

            <button className='font-medium text-white px-3 py-2 rounded-xl flex items-center gap-2 hidden md:flex bg-green-500 hover:scale-110 transition'>
              <WhatsAppIcon />
              Fale Connosco!
            </button>
          </div>
        </div>
      </div>

      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigation.map((item) => (
            <DisclosureButton
              key={item.name}
              as="a"
              href={item.href}
              aria-current={item.current ? 'page' : undefined}
              className={classNames(
                item.current ? 'bg-blue-900/50 text-white' : 'text-sky-700 hover:bg-blue-100 hover:text-blue-500',
                'block rounded-md px-3 py-2 text-blue-500 font-medium',
              )}
            >
              {item.name}
            </DisclosureButton>
          ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
    </AppContainer.Provider>
  )
}
